import "../../../TagsSettingsTagsList/styles/default.scss";

import React from "react";
import { withRouter } from "react-router";

import { UrlProvider } from "../../../../../../api/UrlProvider";
import withOtherAssetsSearchAutocomplete from "../../../../../../hocs/withOtherAssetsSearchAutocomplete";
import withPortfolioOtherAssetsOperations from "../../../../../../hocs/withPortfolioOtherAssetsOperations";
import withTranslations from "../../../../../../hocs/withTranslations";
import Loader from "../../../../common/Loader";
import SquaberButton from "../../../../common/SquaberButton";
import SquaberLink from "../../../../common/SquaberLink";
import TagsSettingsSearchBox from "../../../TagsSettingsSearchBox";

@withTranslations
@withRouter
@withPortfolioOtherAssetsOperations({ autoFetchOtherAssets: true })
@withOtherAssetsSearchAutocomplete
class OtherAssetsList extends React.Component {
  render() {
    const {
      translate,
      locale,
      history,
      inputValue,
      onInputChange,
      otherAssets,
      otherAssetsWithQuery
    } = this.props;

    if (otherAssets.loading) {
      return <Loader />;
    }

    let assets = [];

    if (inputValue.length) {
      assets = otherAssetsWithQuery?.data?.results ?? [];
    } else if (otherAssets?.data?.results) {
      assets = otherAssets.data.results;
    }

    return (
      <div className="tags-settings-tags-list">
        <div className="tags-settings-tags-list-heading">
          <TagsSettingsSearchBox
            label={translate("Type other asset name you're looking for")}
            value={inputValue}
            onChange={event => {
              onInputChange(event.target.value);
            }}
          />
          <SquaberButton
            color="tertiary"
            fullWidth
            onClick={() => {
              history.push(
                UrlProvider.getUrl("fe.otherAssetsAddOtherAsset", { locale })
              );
            }}
          >
            + {translate("Add new other asset")}
          </SquaberButton>
        </div>
        <div className="tags-settings-tags-list-details">
          {assets.length <= 0 ? (
            <strong className={"no-results"}>
              {translate("No other assets")}
            </strong>
          ) : null}
          {assets.map(asset => (
            <SquaberLink
              to={UrlProvider.getUrl("fe.otherAssetSettings", {
                locale,
                id: asset.id
              })}
              key={asset.id}
            >
              <div className="tag-name">
                <span>{asset.name}</span> <i className="fal fa-pen edit-icon" />
              </div>
              {asset?.description ? (
                <div className="tag-description">{asset.description}</div>
              ) : null}
            </SquaberLink>
          ))}
        </div>
      </div>
    );
  }
}

OtherAssetsList.propTypes = {};

export default OtherAssetsList;
