import "../../TagsSettings/styles/default.scss";

import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withPortfolioOtherAssetsOperations from "../../../../../hocs/withPortfolioOtherAssetsOperations";
import withTranslations from "../../../../../hocs/withTranslations";
import ContentSection from "../../../common/ContentSection/modules/default";
import Loader from "../../../common/Loader";
import SquaberLink from "../../../common/SquaberLink";
import OtherAssetsAddNewOtherAsset from "../components/OtherAssetsAddNewOtherAsset";
import OtherAssetsSettingsEditOtherAsset from "../components/OtherAssetsEditOtherAsset/OtherAssetsEditOtherAsset";
import OtherAssetsList from "../components/OtherAssetsList/OtherAssetsList";

class OtherAssetsSettings extends React.Component {
  renderMain() {
    const { translate } = this.props;

    return (
      <React.Fragment>
        <h1>{translate("Other assets settings panel")}</h1>
        <p>{translate("Here you can manage your other assets.")}</p>
        <OtherAssetsList />
      </React.Fragment>
    );
  }

  renderEditOtherAsset() {
    const {
      match: {
        params: { id }
      },
      otherAssets,
      translate,
      editOtherAsset
    } = this.props;

    if (otherAssets.loading) {
      return <Loader />;
    }

    const otherAsset = otherAssets.data.results.find(
      otherAsset => otherAsset.id === Number(id)
    );

    if (typeof otherAsset === "undefined") {
      return (
        <>
          <h1>{translate("Not found other asset")}</h1>
          <p>
            {translate(
              "Please make sure that other asset you want edit exists"
            )}
          </p>
        </>
      );
    }

    return (
      <React.Fragment>
        {this.renderReturnToMainPartial()}
        <OtherAssetsSettingsEditOtherAsset
          otherAsset={otherAsset}
          editOtherAsset={editOtherAsset}
        />
      </React.Fragment>
    );
  }

  renderAddNewOtherAsset() {
    return (
      <React.Fragment>
        {this.renderReturnToMainPartial()}
        <OtherAssetsAddNewOtherAsset />
      </React.Fragment>
    );
  }

  renderReturnToMainPartial() {
    const { translate, locale } = this.props;

    return (
      <React.Fragment>
        <SquaberLink
          className="return-to-tags-list"
          to={UrlProvider.getUrl("fe.settingsPageCategory", {
            locale,
            settingsCategory: "other-assets"
          })}
        >
          <i className="fal fa-arrow-left" />
          <span>{translate("Return to other assets list")}</span>
        </SquaberLink>
      </React.Fragment>
    );
  }

  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <ContentSection className={"tags-settings"}>
        {(path => {
          switch (path) {
            case "/:locale/settings/:settingsCategory/new-other-asset":
              return this.renderAddNewOtherAsset();
            case "/:locale/settings/:settingsCategory/other-asset/:id":
              return this.renderEditOtherAsset();

            default:
              return this.renderMain();
          }
        })(path)}
      </ContentSection>
    );
  }
}

export default compose(
  withTranslations,
  withRouter,
  withPortfolioOtherAssetsOperations({ autoFetchOtherAssets: true })
)(OtherAssetsSettings);
