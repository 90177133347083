import "../../PortfolioStockTransactions/styles/default.scss";

import Formsy from "formsy-react";
import moment from "moment-mini";
import React, { useState } from "react";
import { compose } from "redux";

import Price from "../../../../../common/Price";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withPortfolioAddOtherAssetTransaction from "../../../../../hocs/withPortfolioAddOtherAssetTransaction";
import withPortfolioTransactionHistoryTable from "../../../../../hocs/withPortfolioTransactionHistoryTable";
import Loader from "../../Loader";
import PortfolioAddOtherAssetTransaction from "../../PortfolioAddOtherAssetTransaction";
import SquaberButton from "../../SquaberButton";
import SquaberTable from "../../SquaberTable";

const PortfolioOtherAssetTransactions = ({
  portfolioOtherAssetTransactions,
  loading,
  otherAsset,
  currency,
  translate,
  transactionTypes,
  formBuilder,
  addTransaction,
  calculateCommission,
  clearValidationError,
  validationErrors,
  isMobile,
  deleteTransaction
}) => {
  if (loading !== false) {
    return <Loader small />;
  }

  const [
    showAddOtherAssetTransaction,
    setShowAddOtherAssetTransaction
  ] = useState(false);

  return (
    <div className="portfolio-stock-transactions">
      <Formsy
        onValidSubmit={() => {
          addTransaction(
            {
              formBuilder,
              formModel: {
                asset_id: otherAsset.asset_id
              }
            },
            () => {
              setShowAddOtherAssetTransaction(false);
            }
          );
        }}
        validationErrors={validationErrors}
      >
        <SquaberTable
          heading={[
            translate("Transaction type"),
            translate("Date"),
            translate("Price"),
            translate("Number of unit"),
            translate("Commission"),
            ""
          ]}
          rows={[
            ...portfolioOtherAssetTransactions.map(transaction => {
              return [
                transactionTypes.stock[transaction.type],
                moment(transaction.date).format("DD.MM.YYYY"),
                <Price value={transaction.price} currency={currency} />,
                transaction.shares,
                <Price value={transaction.commission} roundToSecondNumber />,
                <i
                  className="far fa-trash-alt delete-transaction"
                  onClick={() => {
                    deleteTransaction(transaction);
                  }}
                />
              ];
            }),
            !isMobile ? (
              <PortfolioAddOtherAssetTransaction
                formBuilder={formBuilder}
                calculateCommission={calculateCommission}
                clearValidationError={clearValidationError}
                validationErrors={validationErrors}
                loading={loading}
                key="add-other-asset-transaction-form"
              />
            ) : (
              []
            )
          ]}
        />

        {isMobile ? (
          <React.Fragment>
            <SquaberButton
              color="secondary"
              className="add-transaction-button"
              onClick={() => {
                setShowAddOtherAssetTransaction(true);
              }}
            >
              {translate("Add other asset transaction")}
            </SquaberButton>
            {showAddOtherAssetTransaction ? (
              <div className="portfolio-add-cash-transactions is-mobile-page-sheet">
                <h2>{translate("Add stock transaction")}</h2>
                <div
                  className="close-page-sheet"
                  onClick={() => {
                    setShowAddOtherAssetTransaction(false);
                  }}
                >
                  <i className="fal fa-times" />
                </div>
                <SquaberTable
                  headings={[]}
                  rows={[
                    <PortfolioAddOtherAssetTransaction
                      formBuilder={formBuilder}
                      calculateCommission={calculateCommission}
                      clearValidationError={clearValidationError}
                      validationErrors={validationErrors}
                      loading={loading}
                      key="add-other-asset-transaction-form"
                    />
                  ]}
                />
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </Formsy>
    </div>
  );
};

PortfolioOtherAssetTransactions.propTypes = {};

export default compose(
  withPortfolioTransactionHistoryTable,
  withPortfolioAddOtherAssetTransaction,
  withDeviceType
)(PortfolioOtherAssetTransactions);
