import "../../PortfolioStocksTable/styles/default.scss";
import "../../PortfolioAddCashTransactionWrapper/styles/default.scss";

import autoBind from "auto-bind";
import classNames from "classnames";
import Formsy from "formsy-react";
import React from "react";
import { compose } from "redux";

import withPortfolioAddOtherAssetTransaction from "../../../../../hocs/withPortfolioAddOtherAssetTransaction";
import withPortfolioTransactionHistoryTable from "../../../../../hocs/withPortfolioTransactionHistoryTable";
import PortfolioAddOtherAssetTransaction from "../../PortfolioAddOtherAssetTransaction";
import SquaberTable from "../../SquaberTable";

class PortfolioAddStockTransactionWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteValue: "",
      autocompleteBlurred: false
    };

    autoBind.react(this);
  }

  setAutocompleteBlurred(value) {
    this.setState({
      autocompleteBlurred: value
    });
  }

  setAutocompleteValue(value) {
    this.setState({
      autocompleteValue: value
    });
  }

  render() {
    const {
      translate,
      addTransaction,
      formBuilder,
      clearValidationError,
      calculateCommission,
      setShowAddOtherAssetTransaction,
      portfolioData
    } = this.props;

    let {
      addPortfolioStockTransactionState: { loading, error: validationErrors }
    } = this.props;

    const { autocompleteValue } = this.state;

    return (
      <div
        className={classNames({
          "portfolio-add-cash-transactions": true,
          "portfolio-add-stock-transactions": true,
          "scrollable-table-wrapper": true,
          "is-mobile-page-sheet": true
        })}
        ref={element => {
          this.formWrapperElement = element;
        }}
      >
        <h2>{translate("Add other asset transaction")}</h2>
        <div
          className="close-page-sheet"
          onClick={() => {
            setShowAddOtherAssetTransaction(false);
          }}
        >
          <i className="fal fa-times" />
        </div>
        <Formsy
          onValidSubmit={model => {
            addTransaction({
              formBuilder,
              formModel: {
                ...model,
                ...(autocompleteValue?.stockData
                  ? { asset_id: autocompleteValue.stockData }
                  : {})
              }
            });
          }}
          validationErrors={validationErrors}
        >
          <SquaberTable
            className="portfolio-stocks-table"
            heading={[
              translate("Name"),
              translate("Transaction type"),
              translate("Date"),
              translate("Price"),
              translate("Number of unit"),
              translate("Commission"),
              ""
            ]}
            rows={[
              <PortfolioAddOtherAssetTransaction
                formBuilder={formBuilder}
                loading={loading}
                withOtherAssetSelector
                autocompleteValue={autocompleteValue}
                setAutocompleteValue={this.setAutocompleteValue}
                setAutocompleteBlurred={this.setAutocompleteBlurred}
                calculateCommission={calculateCommission}
                clearValidationError={clearValidationError}
                validationErrors={validationErrors}
                portfolioData={portfolioData}
                key="add-other-asset-transaction"
              />
            ]}
          />
        </Formsy>
      </div>
    );
  }
}

export default compose(
  withPortfolioTransactionHistoryTable,
  withPortfolioAddOtherAssetTransaction
)(PortfolioAddStockTransactionWrapper);
