import React from "react";

import withFormBuilder from "./withFormBuilder";
import withTranslations from "./withTranslations";

export default function withOtherAssetsDetailsForm(WrappedComponent) {
  @withTranslations
  @withFormBuilder(props => {
    const { otherAsset } = props;

    return [
      {
        name: "name",
        label: "Other asset name",
        value: otherAsset?.name ?? "",
        type: "text",
        fullWidth: true,
        required: true,
        maxLength: 40,
        validations: {
          maxLength: 40
        },
        validationErrors: {
          isDefaultRequiredValue: "This field is required",
          maxLength: "Other asset name can't be longer than 40 characters"
        }
      },
      {
        name: "description",
        label: "Other asset description",
        value: otherAsset?.description ?? "",
        type: "text",
        fullWidth: true,
        required: false,
        multiline: true,
        validationErrors: {
          isDefaultRequiredValue: "This field is required"
        }
      }
    ];
  })
  class WithOtherAssetDetailsForm extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithOtherAssetDetailsForm;
}
