import React, { useEffect, useState } from "react";
import { compose } from "redux";

import withPortfolioOtherAssetsOperations from "../../../../../hocs/withPortfolioOtherAssetsOperations";
import withTranslations from "../../../../../hocs/withTranslations";
import PortfolioOtherAssetTransactions from "../../PortfolioOtherAssetTransactions";
import SquaberTable from "../../SquaberTable";
import PortfolioOtherAssetsTableRow from "../components/PortfolioOtherAssetsTableRow";
const PortfolioOtherAssetsTable = ({
  translate,
  currency,
  portfolioData,
  getPortfolioOtherAssetsTransactions,
  portfolioOtherAssetsTransactionsState
}) => {
  const [currentOtherAsset, setCurrentOtherAsset] = useState();

  useEffect(() => {
    if (currentOtherAsset) {
      getPortfolioOtherAssetsTransactions();
    }
  }, [currentOtherAsset]);

  const rows = portfolioData.components.assets.map(asset => {
    const isCurrent = currentOtherAsset
      ? asset.asset_id === currentOtherAsset.asset_id
      : false;

    return (
      <PortfolioOtherAssetsTableRow
        key={asset.asset_id}
        asset={asset}
        isCurrent={isCurrent}
        setCurrentOtherAsset={setCurrentOtherAsset}
      />
    );
  });

  if (currentOtherAsset) {
    const indexToAppendAfter = portfolioData.components.assets.findIndex(
      asset => asset.asset_id === currentOtherAsset.asset_id
    );

    if (indexToAppendAfter !== -1) {
      rows.splice(indexToAppendAfter + 1, 0, [
        {
          content: (
            <PortfolioOtherAssetTransactions
              key={`${currentOtherAsset.asset_id}-${indexToAppendAfter + 1}`}
              portfolioOtherAssetTransactions={
                portfolioOtherAssetsTransactionsState?.data?.[
                  currentOtherAsset.asset_name
                ] ?? []
              }
              loading={portfolioOtherAssetsTransactionsState?.loading}
              otherAsset={currentOtherAsset}
              currency={currency}
            />
          ),
          colspan: 9
        }
      ]);
    }
  }

  return (
    <div className="scrollable-table-wrapper">
      <SquaberTable
        className="portfolio-stocks-table"
        heading={[
          translate("Name"),
          translate("Number of units"),
          translate("Unit price [%{currency}]", { currency }),
          translate("Transaction value [%{currency}]", { currency }),
          ""
        ]}
        rows={[...rows]}
      />
    </div>
  );
};

export default compose(
  withTranslations,
  withPortfolioOtherAssetsOperations()
)(PortfolioOtherAssetsTable);
