import { createAction, handleActions } from "redux-actions";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const CLEAR_ADD_TRANSACTION_VALIDATION_ERROR =
  "app/portfolios/CLEAR_ADD_TRANSACTION_VALIDATION_ERROR";

export const clearAddTransactionValidationError = createAction(
  CLEAR_ADD_TRANSACTION_VALIDATION_ERROR,
  (field: string) => ({ field })
);

export const CLEAR_ALL_ADD_TRANSACTION_VALIDATION_ERRORS =
  "app/portfolios/CLEAR_ALL_ADD_TRANSACTION_VALIDATION_ERRORS";

export const clearAllAddTransactionValidationErrors = createAction(
  CLEAR_ALL_ADD_TRANSACTION_VALIDATION_ERRORS
);

export const CLEAR_ADD_CASH_TRANSACTION_VALIDATION_ERROR =
  "app/portfolios/CLEAR_ADD_CASH_TRANSACTION_VALIDATION_ERROR";

export const CLEAR_ADD_EDIT_PORTFOLIO_VALIDATION_ERROR =
  "app/portfolios/CLEAR_ADD_EDIT_PORTFOLIO_VALIDATION_ERROR";

export const clearAddCashTransactionValidationError = createAction(
  CLEAR_ADD_CASH_TRANSACTION_VALIDATION_ERROR,
  (field: string) => ({ field })
);

export const getPortfolioRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIO"
);

export const getPortfolioChartRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIO_CHART"
);

export const getPortfolioTransactionHistoryRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIO_TRANSACTION_HISTORY"
);

export const deleteTransactionRoutine = createRoutine(
  "app/portfolios/DELETE_TRANSACTION"
);

export const getPortfoliosListRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIOS_LIST"
);

export const getPortfoliosStockRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIOS_STOCK"
);

export const removePortfolioRoutine = createRoutine(
  "app/portfolios/REMOVE_PORTFOLIO"
);

const CLEAR_PORTFOLIOS_LIST = "app/portfolios/REMOVE_PORTFOLIO";

export const clearPortfoliosList = createAction(
  CLEAR_PORTFOLIOS_LIST,
  () => ({})
);

export const addPortfolioRoutine = createRoutine(
  "app/portfolios/ADD_PORTFOLIO"
);

export const editPortfolioRoutine = createRoutine(
  "app/portfolios/EDIT_PORTFOLIO"
);

export const getPortfolioStockTransactionsRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIO_STOCK_TRANSACTIONS"
);

export const addPortfolioTransactionRoutine = createRoutine(
  "app/portfolios/ADD_PORTFOLIO_TRANSACTION"
);
export const getOtherAssetsRoutine = createRoutine(
  "app/portfolios/GET_OTHER_ASSETS"
);
export const editOtherAssetRoutine = createRoutine(
  "app/portfolios/EDIT_OTHER_ASSET"
);

export const editOtherAssetPromiseCreator = promisifyRoutine(
  editOtherAssetRoutine
);

export const createNewOtherAssetRoutine = createRoutine(
  "app/portfolios/CREATE_NEW_OTHER_ASSET_ROUTINE"
);
export const createNewOtherAssetPromiseCreator = promisifyRoutine(
  createNewOtherAssetRoutine
);

export const getPortfolioCashTransactionsRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIO_CASH_TRANSACTIONS"
);

export const getPortfolioAssetsTransactionsRoutine = createRoutine(
  "app/portfolios/GET_PORTFOLIO_ASSETS_TRANSACTIONS"
);

export const addPortfolioCashTransactionRoutine = createRoutine(
  "app/portfolios/ADD_PORTFOLIO_CASH_TRANSACTION"
);

export const refreshPortfolioRoutine = createRoutine(
  "app/portfolios/REFRESH_PORTFOLIO"
);

export const clearAddEditPortfolioValidationErrors = createAction(
  CLEAR_ADD_EDIT_PORTFOLIO_VALIDATION_ERROR
);

const initialState = {
  portfolios: null,
  portfoliosLoading: true,
  currentPortfolio: null,
  currentPortfolioLoading: true,
  chart: {
    loading: false,
    data: [],
    lastUsedType: "rate_of_return_chart"
  },
  transactionHistory: {
    loading: true,
    data: null
  },
  list: {
    loading: true,
    data: null,
    error: null
  },
  otherAssets: {
    loading: true,
    data: null,
    error: null
  },
  stockTransactions: {
    loading: true,
    data: null
  },
  cashTransactions: {
    loading: true,
    data: null
  },
  assetsTransactions: {
    loading: true,
    data: null
  },
  addPortfolioCashTransaction: {
    loading: false,
    error: null
  },
  addPortfolioStockTransaction: {
    loading: false,
    error: null
  },
  addEditPortfolioState: {
    loading: false,
    error: null
  },
  portfoliosStock: {
    loading: false,
    error: null,
    data: null
  }
};

export default handleActions(
  {
    [getPortfolioRoutine.TRIGGER]: state => ({
      ...state,
      currentPortfolioLoading: true
    }),
    [getPortfolioRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentPortfolio: payload.data,
      currentPortfolioLoading: false,
      transactionHistory: payload.isRefreshing
        ? state.transactionHistory
        : initialState.transactionHistory
    }),
    [getPortfolioRoutine.FAILURE]: state => ({
      ...state,
      currentPortfolioLoading: false
    }),
    [getPortfolioChartRoutine.TRIGGER]: state => ({
      ...state,
      chart: {
        ...state.chart,
        loading: true
      }
    }),
    [getPortfolioChartRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      chart: {
        ...state.chart,
        data: payload.data,
        loading: false,
        lastUsedType: payload.lastUsedType
      }
    }),
    [getPortfolioChartRoutine.FAILURE]: state => ({
      ...state,
      chart: {
        ...state.chart,
        loading: false
      }
    }),
    [getPortfolioTransactionHistoryRoutine.TRIGGER]: state => ({
      ...state,
      transactionHistory: {
        ...state.transactionHistory,
        loading: true
      }
    }),
    [getPortfolioTransactionHistoryRoutine.SUCCESS]: (
      state,
      { payload: { data, page } }
    ) => {
      let newTransactionHistory = { ...state.transactionHistory };

      if (page) {
        newTransactionHistory = {
          ...state.transactionHistory,
          count: data.count,
          next: data.next,
          results: [
            ...(state.transactionHistory.data
              ? state.transactionHistory.data.results
              : []),
            ...data.results
          ]
        };
      } else {
        newTransactionHistory = data;
      }

      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          loading: false,
          data: newTransactionHistory
        }
      };
    },
    [getPortfolioTransactionHistoryRoutine.FAILURE]: state => ({
      ...state,
      transactionHistory: {
        ...state.transactionHistory,
        loading: false
      }
    }),
    [getPortfoliosListRoutine.TRIGGER]: state => ({
      ...state,
      list: {
        ...state.list,
        loading: true,
        error: null
      }
    }),
    [getPortfoliosListRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
        data: payload,
        error: null
      }
    }),
    [getPortfoliosListRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
        error: payload
      }
    }),
    [getPortfoliosStockRoutine.TRIGGER]: state => ({
      ...state,
      portfoliosStock: {
        ...state.portfoliosStock,
        loading: true
      }
    }),
    [getPortfoliosStockRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      portfoliosStock: {
        ...state.portfoliosStock,
        loading: false,
        data: payload
      }
    }),
    [getPortfoliosStockRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      portfoliosStock: {
        ...state.portfoliosStock,
        loading: false,
        error: payload
      }
    }),
    [CLEAR_PORTFOLIOS_LIST]: state => ({
      ...state,
      list: initialState.list
    }),
    [getPortfolioStockTransactionsRoutine.TRIGGER]: state => ({
      ...state,
      stockTransactions: {
        ...state.stockTransactions,
        loading: true
      }
    }),
    [getPortfolioStockTransactionsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      stockTransactions: {
        ...state.stockTransactions,
        loading: false,
        data: payload
      }
    }),
    [getPortfolioStockTransactionsRoutine.FAILURE]: state => ({
      ...state,
      stockTransactions: {
        ...state.stockTransactions,
        loading: false
      }
    }),
    [addPortfolioTransactionRoutine.TRIGGER]: state => ({
      ...state,
      addPortfolioStockTransaction: {
        ...state.addPortfolioStockTransaction,
        loading: true,
        error: null
      }
    }),
    [addPortfolioTransactionRoutine.SUCCESS]: (state, { payload }) => {
      if (payload?.asset_name) {
        let canMargePayload = false;
        let newAssetsTransactions = { ...state.assetsTransactions };
        const transactions = newAssetsTransactions?.data?.[payload.asset_name];

        if (Array.isArray(transactions)) {
          canMargePayload = !transactions.some(
            ({ id, asset_name }) =>
              id === payload.id || asset_name !== payload.asset_name
          );
        }

        if (canMargePayload) {
          newAssetsTransactions.data = {
            ...newAssetsTransactions.data,
            [payload.asset_name]: [...transactions, payload]
          };
        }

        return {
          ...state,
          assetsTransactions: newAssetsTransactions,
          addPortfolioStockTransaction: {
            ...state.addPortfolioStockTransaction,
            loading: false,
            error: null
          }
        };
      } else {
        const { id: payloadId, stock: payloadStock } = payload;

        let canMargePayload = false;
        let newStockTransactions = { ...state.stockTransactions };
        const transactions = newStockTransactions?.data?.transactions;

        if (Array.isArray(transactions)) {
          canMargePayload = !transactions.some(
            ({ id, stock }) => id === payloadId || stock !== payloadStock
          );
        }

        if (canMargePayload) {
          newStockTransactions.data = {
            ...newStockTransactions.data,
            transactions: [...transactions, payload]
          };
        }

        return {
          ...state,
          stockTransactions: newStockTransactions,
          addPortfolioStockTransaction: {
            ...state.addPortfolioStockTransaction,
            loading: false,
            error: null
          }
        };
      }
    },
    [addPortfolioTransactionRoutine.FAILURE]: (state, { payload }) => {
      const validationErrors = {};

      for (let field of payload) {
        if (field.field === "stock") {
          validationErrors.ticker = field.value;
        } else {
          validationErrors[field.field] = field.value;
        }
      }

      return {
        ...state,
        addPortfolioStockTransaction: {
          ...state.addPortfolioStockTransaction,
          loading: false,
          error: validationErrors
        }
      };
    },
    [clearAddTransactionValidationError]: (state, action) => {
      let validationErrors = { ...state.addPortfolioStockTransaction.error };

      delete validationErrors[action.payload.field];

      return {
        ...state,
        addPortfolioStockTransaction: {
          ...state.addPortfolioStockTransaction,
          error: validationErrors
        }
      };
    },
    [clearAllAddTransactionValidationErrors]: state => {
      return {
        ...state,
        addPortfolioStockTransaction: {
          ...state.addPortfolioStockTransaction,
          error: null
        }
      };
    },
    [clearAddCashTransactionValidationError]: (state, action) => {
      let validationErrors = { ...state.addPortfolioCashTransaction.error };

      delete validationErrors[action.payload.field];

      return {
        ...state,
        addPortfolioCashTransaction: {
          ...state.addPortfolioCashTransaction,
          error: validationErrors
        }
      };
    },
    [getPortfolioCashTransactionsRoutine.TRIGGER]: state => ({
      ...state,
      cashTransactions: {
        ...state.cashTransactions,
        loading: true
      }
    }),
    [getPortfolioCashTransactionsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      cashTransactions: {
        ...state.cashTransactions,
        loading: false,
        data: payload
      }
    }),
    [getPortfolioCashTransactionsRoutine.FAILURE]: state => ({
      ...state,
      cashTransactions: {
        ...state.cashTransactions,
        loading: false
      }
    }),
    [getPortfolioAssetsTransactionsRoutine.TRIGGER]: state => ({
      ...state,
      assetsTransactions: {
        ...state.assetsTransactions,
        loading: true
      }
    }),
    [getPortfolioAssetsTransactionsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      assetsTransactions: {
        ...state.assetsTransactions,
        loading: false,
        data: payload
      }
    }),
    [getPortfolioAssetsTransactionsRoutine.FAILURE]: state => ({
      ...state,
      assetsTransactions: {
        ...state.assetsTransactions,
        loading: false
      }
    }),
    [addPortfolioCashTransactionRoutine.TRIGGER]: state => ({
      ...state,
      addPortfolioCashTransaction: {
        loading: true
      }
    }),
    [addPortfolioCashTransactionRoutine.SUCCESS]: state => ({
      ...state,
      addPortfolioCashTransaction: {
        loading: false
      }
    }),
    [addPortfolioCashTransactionRoutine.FAILURE]: (state, { payload }) => {
      const validationErrors = {};

      for (let field of payload) {
        validationErrors[field.field] = field.value;
      }

      return {
        ...state,
        addPortfolioCashTransaction: {
          loading: false,
          error: validationErrors
        }
      };
    },
    [deleteTransactionRoutine.SUCCESS]: (state, { payload }) => {
      let newStockTransactions = { ...state.stockTransactions };

      if (
        newStockTransactions.data &&
        newStockTransactions.data.transactions.length
      ) {
        newStockTransactions.data.transactions = newStockTransactions.data.transactions.filter(
          transaction => transaction.id !== payload.transactionId
        );
      }

      let newCashTransactions = { ...state.cashTransactions };

      if (newCashTransactions.data && newCashTransactions.data.results.length) {
        newCashTransactions.data.results = newCashTransactions.data.results.filter(
          transaction => transaction.id !== payload.transactionId
        );
      }

      let newTransactionHistory = { ...state.transactionHistory };
      if (
        newTransactionHistory.data &&
        newTransactionHistory.data.results.length
      ) {
        newTransactionHistory.data.results = newTransactionHistory.data.results.filter(
          transaction => transaction.id !== payload.transactionId
        );
      }

      let newAssetsTransactions = { ...state.assetsTransactions };

      const assetName = payload?.transaction?.asset_name;

      if (
        assetName &&
        newAssetsTransactions.data &&
        newAssetsTransactions.data[assetName]
      ) {
        newAssetsTransactions.data[assetName] = newAssetsTransactions.data[
          assetName
        ].filter(transaction => transaction.id !== payload.transaction.id);
      }

      return {
        ...state,
        stockTransactions: newStockTransactions,
        cashTransactions: newCashTransactions,
        transactionHistory: newTransactionHistory,
        assetsTransactions: newAssetsTransactions
      };
    },
    [addPortfolioRoutine.TRIGGER]: state => ({
      ...state,
      addEditPortfolioState: {
        ...state.addEditPortfolioState,
        loading: true
      }
    }),
    [addPortfolioRoutine.SUCCESS]: state => ({
      ...state,
      addEditPortfolioState: {
        ...state.addEditPortfolioState,
        loading: false
      }
    }),
    [addPortfolioRoutine.FAILURE]: (state, { payload }) => {
      const validationErrors = {};

      for (let field of payload) {
        validationErrors[field.field] = field.value;
      }

      return {
        ...state,
        addEditPortfolioState: {
          ...state.addEditPortfolioState,
          loading: false,
          error: validationErrors
        }
      };
    },
    [getOtherAssetsRoutine.TRIGGER]: state => ({
      ...state,
      otherAssets: {
        loading: true,
        data: null,
        error: null
      }
    }),
    [getOtherAssetsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      otherAssets: {
        loading: false,
        data: payload,
        error: null
      }
    }),
    [getOtherAssetsRoutine.FAILURE]: (state, { payload }) => {
      return {
        ...state,
        otherAssets: {
          loading: false,
          data: null,
          error: payload
        }
      };
    },
    [createNewOtherAssetRoutine.SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        otherAssets: {
          ...state.otherAssets,
          data: {
            ...state.otherAssets.data,
            results: [...state.otherAssets.data.results, payload]
          }
        }
      };
    },
    [CLEAR_ADD_EDIT_PORTFOLIO_VALIDATION_ERROR]: state => ({
      ...state,
      addEditPortfolioState: {
        error: null
      }
    })
  },
  initialState
);
